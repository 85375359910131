import request from "../customUtil/request"
import queryString from "query-string"

export async function getUnit(id) {
  return request(`admin/units/${id}`, {
    method: "GET",
  })
}

export async function getRegion() {
  return request("admin/regions", {
    method: "GET",
  })
}
export async function getAllRegion() {
  return request("store/regions/list", {
    method: "GET",
  })
}

export async function addProduct(data) {
  return request("admin/products", {
    method: "POST",
    data: data,
  })
}

export async function addProductCsv(data) {
  return request("admin/product/upload-csv", {
    method: "POST",
    data: data,
  })
}
export async function dowloadTemplateCsv() {
  return request("/admin/product/export-csv", {
    method: "GET",
  })
}

export async function dowloadProductCsv(params) {
  return request("/admin/product/export-csv/list", {
    method: "GET",
    params: params,
  })
}
export async function getProduct(obj) {
  return request(`admin/products`, {
    method: "GET",
    params: obj,
  })
}

export async function getProductId(id) {
  return request(`admin/products/${id}`, {
    method: "GET",
  })
}

export async function updateProductId(id, data) {
  return request(`admin/products/${id}`, {
    method: "POST",
    data: data,
  })
}

export async function getVariants(obj) {
  return request(`admin/variants`, {
    method: "GET",
    params: obj,
  })
}

export async function updateVariant(data) {
  return request(`admin/variant/`, {
    method: "POST",
    data: data,
  })
}

export async function deleteVariant(data) {
  return request(`admin/variant/delete`, {
    method: "POST",
    data: data,
  })
}

export async function getCollectionId(id) {
  return request(`admin/collections/${id}`, {
    method: "GET",
  })
}

export async function searchVariants(status, params) {
  return request(`admin/variants?proStatus[]=${status}`, {
    method: "GET",
    params: params,
  })
}

export async function getWarehouse() {
  return request(`admin/warehouse/select`, {
    method: "GET",
  })
}

export async function deleteImages(data) {
  return request(`admin/image/delete`, {
    method: "POST",
    data: data,
  })
}

export async function deleteThumbnail(data) {
  return request(`admin/delete-thumbnail`, {
    method: "POST",
    data: data,
  })
}

export async function getOrders({ params }) {
  const queryStr = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
    arrayFormat: "bracket",
  })
  return request(`/admin/orders?${queryStr}`, {
    method: "GET",
  })
}

export async function getAnOrder({ orderId }) {
  return request(`/admin/orders/${orderId}`, {
    method: "GET",
  })
}

export async function productNegotiate(data) {
  return request(`/admin/product-negotiate`, {
    method: "POST",
    data: data,
  })
}

export async function productListNegotiate(data) {
  return request(`/admin/list-product-negotiate`, {
    method: "POST",
    data: data,
  })
}

export async function changeStatus(id, data) {
  return request(`admin/orders/change-status/${id}`, {
    method: "POST",
    data,
  })
}

export async function handleAddAddressStoreId(params, data) {
  return request(`admin/super/customer/address`, {
    method: "POST",
    params,
    data,
  })
}

export async function getListAddressStoreId(params) {
  return request(`/store/customer/listAdd`, {
    method: "GET",
    params,
  })
}

export async function handleUpdateAddress(addressId, data) {
  return request(`/store/customer/address/${addressId}`, {
    method: "PUT",
    data: data,
  })
}

export async function deleteAddress(addressId) {
  return request(`/store/customer/address/${addressId}`, {
    method: "DELETE",
  })
}
